<template>
    <v-footer class="primary" app>
        <div class="white--text text-right" style="width: 100%;" v-html="$store.getters.copy"></div>
    </v-footer>
</template>
<style scoped>
.v-application .primary {
    background-color: #1b4e82 !important;
    border-color: #1b4e82 !important;
}
</style>
<script>
export default {
    name: 'Footer',
    data: () => ({
    }),
    methods: {
    }
}
</script>
