<template>
    <v-snackbar
      v-model="$store.getters.msg.show"
      :color="$store.getters.msg.color"
      multi-line="multi-line"
      v-bind:timeout="$store.getters.msg.timeout"
      top="top"
    >
    {{ $store.getters.msg.message }}
    <v-btn
      dark
      text
      @click="$store.commit('msgOculta')"
    >
      Close
    </v-btn>
    </v-snackbar>
</template>
<style scoped>
</style>
<script>
export default {
    name: 'Msg',
    data: () => ({
    }),
    methods: {
    }
}
</script>
