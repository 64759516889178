<template>
<!-- App.vue -->
<v-app>

    <Msg />
    <Menu v-if="$store.getters.login.login"/>
    <Bar />
    <!-- Sizes your content based upon application components -->
    <v-main>
        <!-- Provides the application the proper gutter -->
        <v-container fluid>
            <!-- If using vue-router -->
            <router-view></router-view>
        </v-container>
    </v-main>
    <Footer />
</v-app>

</template>

<script>
import Menu from './components/Menu'
import Bar from './components/Bar'
import Footer from './components/Footer'
import Msg from './components/Msg'

export default {
    name: 'App',
    data: () => ({
    }),
    components: {
        Menu,
        Bar,
        Footer,
        Msg,
    },
    mounted: function(){
        var este = this;
        if(localStorage.getItem('teacher_id') !== null){
            var datos = {
                id: localStorage.getItem('student_id'),
                token: localStorage.getItem('student_token'),
                name: localStorage.getItem('student_name'),
                last_name: localStorage.getItem('student_last_name'),
                gender: localStorage.getItem('student_gender'),
                birthday: localStorage.getItem('student_birthday'),
                email: localStorage.getItem('student_email'),
                phone: localStorage.getItem('student_phone'),
                mobile: localStorage.getItem('student_mobile'),
                street: localStorage.getItem('student_street'),
                number: localStorage.getItem('student_number'),
                city: localStorage.getItem('student_city'),
                zip_code: localStorage.getItem('student_zip_code'),
                state: localStorage.getItem('student_state'),
                picture: localStorage.getItem('student_picture'),
                updated_at: localStorage.getItem('student_updated_at')
            }
            este.$store.commit('loginSet', datos);
        }
    }
}
</script>
