import Vue from 'vue'
// import './plugins/axios'
import axios from './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'

Vue.config.productionTip = false

Vue.prototype.$http = axios

if(process.env.NODE_ENV == 'production'){
    Vue.prototype.$url = 'https://educational.primawm.com/api/student/';
} else {
    Vue.prototype.$url = 'http://primaeducational.test/api/student/';
}

// Validation rules
Vue.prototype.$rules = {
    required: [
        v => !!v || "This field is required",
    ],
    password: [
        v => !!v || "This field is required",
        v => (v && /.{8,}/.test(v)) || "The password must contain at least 8 characters",
    ],
    requiredEmail: [
        v => !!v || "This field is required",
        v => (v && /.+@.+\..{2,}/.test(v)) || "This field must be a valid email",
    ],
    required_phone: [
        v => !!v || "This field is required",
        v => (v && /^\d{10}$/.test(v)) || "This field only accepts 10 numbers",
    ],
    phone: [
        v => (!v || /^\d{10}$/.test(v)) || "This field only accepts 10 digits",
    ],
}
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
