<template>
    <v-navigation-drawer v-model="$store.state.drawer" app>
        <v-list>
            <v-list-item class="px-2">
                <v-list-item-avatar>
                    <v-img v-show="$store.getters.login.picture" :src="$store.getters.login.picture"></v-img>
                </v-list-item-avatar>
            </v-list-item>

            <v-list-item link>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                    {{ $store.getters.login.name }} {{ $store.getters.login.last_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ $store.getters.login.email }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list nav dense>
            <v-list-item link v-for="(item, index) in $store.getters.menu" :key="index" :to="item.action">
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.text  }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
export default {
    name: 'Menu',
    data: () => ({
    }),
    methods: {
    }
}
</script>
