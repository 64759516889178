import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
        login: {
            login: false,
            id: 0,
            token: '',
            name: '',
            last_name: '',
            gender: '',
            birthday: '',
            email: '',
            phone: '',
            mobile: '',
            street: '',
            number: '',
            city: '',
            zip_code: '',
            state: '',
            picture: '',
            updated_at: '',
        },
        msg: {
            show: false,
            color: 'red',
            timeout: 5000,
            message: '',
        },
        drawer: false,
        title: "Student's App",
        copy: '&copy; ' + new Date().getFullYear() + ' Prima Educational',
        menu: [
            { icon: 'mdi-home', text: 'Home', action: '/' },
            { icon: 'mdi-account-edit', text: 'Profile', action: '/profile' },
            { icon: 'mdi-google-classroom', text: 'Classes', action: '/classes' },
            { icon: 'mdi-checkbox-marked-outline', text: 'Surveys', action: '/surveys' },
            { icon: 'mdi-chart-bar', text: 'Reports', action: '/reports' },
            { icon: 'mdi-logout', text: 'Logout', action: '/logout' },
        ],
  },
  mutations: {
      muestraMenu(state){
          state.drawer = !state.drawer;
          console.log(state.drawer);
      },
      logout: function(state){
          state.login.login = false;
          state.id = 0;
          state.token = '';
          state.login.name = '';
          state.login.last_name = '';
          state.login.gender = '';
          state.login.birthday = '';
          state.login.email = '';
          state.login.phone = '';
          state.login.mobile = '';
          state.login.street = '';
          state.login.number = '';
          state.login.city = '';
          state.login.zip_code = '';
          state.login.state = '';
          state.login.picture = '';
          state.login.updated_at = '';
          localStorage.removeItem('student_token');
          localStorage.removeItem('student_id');
          localStorage.removeItem('student_name');
          localStorage.removeItem('student_last_name');
          localStorage.removeItem('student_gender');
          localStorage.removeItem('student_birthday');
          localStorage.removeItem('student_email');
          localStorage.removeItem('student_phone');
          localStorage.removeItem('student_mobile');
          localStorage.removeItem('student_street');
          localStorage.removeItem('student_number');
          localStorage.removeItem('student_city');
          localStorage.removeItem('student_zip_code');
          localStorage.removeItem('student_state');
          localStorage.removeItem('student_picture');
          localStorage.removeItem('student_updated_at');
          router.push('/login');
      },
      loginSend: function(state, datos){
          state.login.login = true;
          state.login.id = datos.id;
          state.login.token = datos.token;
          state.login.name = datos.name;
          state.login.last_name = datos.last_name;
          state.login.gender = datos.gender;
          state.login.birthday = datos.birthday;
          state.login.email = datos.email;
          state.login.phone = datos.phone;
          state.login.mobile = datos.mobile;
          state.login.street = datos.street;
          state.login.number = datos.number;
          state.login.city = datos.city;
          state.login.zip_code = datos.zip_code;
          state.login.state = datos.state;
          state.login.picture = datos.picture;
          state.login.updated_at = datos.updated_at;
          localStorage.setItem('student_token', datos.token);
          localStorage.setItem('student_id', datos.id);
          localStorage.setItem('student_name', datos.name);
          localStorage.setItem('student_last_name', datos.last_name);
          localStorage.setItem('student_gender', datos.gender);
          localStorage.setItem('student_birthday', datos.birthday);
          localStorage.setItem('student_email', datos.email);
          localStorage.setItem('student_phone', datos.phone);
          localStorage.setItem('student_mobile', datos.mobile);
          localStorage.setItem('student_street', datos.street);
          localStorage.setItem('student_number', datos.number);
          localStorage.setItem('student_city', datos.city);
          localStorage.setItem('student_zip_code', datos.zip_code);
          localStorage.setItem('student_state', datos.state);
          localStorage.setItem('student_picture', datos.picture);
          localStorage.setItem('student_updated_at', datos.updated_at);
          router.push('/');
      },
      loginSet: function(state, datos){
          state.login.login = true;
          state.login.id = datos.id;
          state.login.token = datos.token;
          state.login.name = datos.name;
          state.login.last_name = datos.last_name;
          state.login.gender = datos.gender;
          state.login.birthday = datos.birthday;
          state.login.email = datos.email;
          state.login.phone = datos.phone;
          state.login.mobile = datos.mobile;
          state.login.street = datos.street;
          state.login.number = datos.number;
          state.login.city = datos.city;
          state.login.zip_code = datos.zip_code;
          state.login.state = datos.state;
          state.login.picture = datos.picture;
          state.login.updated_at = datos.updated_at;
          //router.push('/');
      },
      setPicture: function(state, picture){
          state.login.picture = picture;
      },
      msgMuestra: function(state, vars){
          state.msg.color = vars.color;
          state.msg.timeout = vars.timeout;
          state.msg.message = vars.message;
          state.msg.show = true;
      },
      msgOculta: function(state){
          state.msg.color = 'red';
          state.msg.timeout = 5000;
          state.msg.message = '';
          state.msg.show = false;
      },

  },
  actions: {
  },
  getters: {
      drawer: state => state.drawer,
      title: state => state.title,
      copy: state => state.copy,
      menu: state => state.menu,
      login: state => state.login,
      msg: state  => state.msg,
  },
  modules: {
  }
})
