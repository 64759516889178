import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/classes',
    name: 'Classes',
    component: () => import(/* webpackChunkName: "classes" */ '../views/Classes.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/surveys',
    name: 'Surveys',
    component: () => import(/* webpackChunkName: "surveys" */ '../views/Surveys.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue'),
    meta: { requiresAuth: true }
  },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    window.console.log(router);
    if (to.matched.some(record => record.meta.requiresAuth)) {
        //if (!router.app.$token) {
        if (!store.getters.login.login) {
            window.console.log('Requiere auth');
            next('/login');
            return;
        }
    }
    /*if (to.matched.some(record => record.meta.isManager)) {
        //if (!router.app.$token) {
        if (store.getters.driver_type != 'Manager') {
            window.console.log('Requiere auth Manager')  ;
            next('/login');
            return;
        }
    }*/
    next();
});

export default router
