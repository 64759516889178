<template>
    <v-app-bar class="primary" dark app>
        <v-app-bar-nav-icon @click="$store.commit('muestraMenu')" v-if="$store.getters.login.login"></v-app-bar-nav-icon>
        <v-toolbar-title>{{$store.getters.title}}</v-toolbar-title>
        <v-spacer />
        <v-btn icon large>
            <v-avatar size="42px" item>
            <v-img src="@/assets/logo2.png" alt="PRIMA Educational" /></v-avatar>
        </v-btn>
    </v-app-bar>
</template>
<style scoped>
.v-application .primary {
    background-color: #1b4e82 !important;
    border-color: #1b4e82 !important;
}
</style>
<script>
export default {
    name: 'Bar',
    data: () => ({
    }),
    methods: {
    }
}
</script>
